import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'APP',
  setup() {
    const data = reactive({});
    const router = useRouter();
    const setFontSize = () => {
      const docEl = document.documentElement;
      let clientWidth = docEl.clientWidth || window.innerWidth;
      console.log(clientWidth, 'clientWidth');
      if (!clientWidth) return;
      if (clientWidth < 1000) {
        router.replace({
          path: '/h5'
        });
      }
      if (clientWidth > 1000) {
        router.replace({
          path: '/'
        });
      }
    };

    // 在组件挂载时执行
    onMounted(() => {
      setFontSize();
      window.addEventListener('resize', setFontSize);
    });
    return {
      ...toRefs(data)
    };
  }
});