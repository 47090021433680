import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/pc_web/index.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/h5',
    name: 'H5',
    component: () => import('../views/h5_web/index.vue'),
    meta: {
      keepAlive: false
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  next() // 则执行next()方法 跳转到下一个页面（自己设置）
})

export default router
